import constants from '@/config/constants'

const EXTRACTION_SOURCES = {
  NLP_ENTITY: {
    value: 'NLP_ENTITY'
  },
  FREE_DATA: {
    value: 'FREE_DATA'
  },
  CONTEXT: {
    value: 'CONTEXT'
  },
  BOOLEAN: {
    value: 'BOOLEAN'
  },
  EXTERNAL_FULFILLMENT: {
    value: 'EXTERNAL_FULFILLMENT'
  },
  GENERATIVE_AI: {
    value: 'GENERATIVE_AI'
  },
  DELEGATED: {
    value: 'DELEGATED'
  }
}

const INTENTS = {
  APPROVAL: {
    type: EXTRACTION_SOURCES.BOOLEAN.value,
    value: 'APPROVAL',
    masterPropName: 'approvalMasterIntentIds',
    propName: 'approvalIntentIds'
  },
  REFUSAL: {
    type: EXTRACTION_SOURCES.BOOLEAN.value,
    value: 'REFUSAL',
    masterPropName: 'refusalMasterIntentIds',
    propName: 'refusalIntentIds'
  },
  NLP_INTENT: {
    tabName: 'VALUE_EXTRACTION',
    type: EXTRACTION_SOURCES.NLP_ENTITY.value,
    value: EXTRACTION_SOURCES.NLP_ENTITY.value,
    masterPropName: 'masterIntentIds',
    propName: 'intentIds'
  }
}

const CORE_TABS = {
  CORE: {
    componentName: 'Core',
    tabName: 'core'
  },
  CUSTOM_CORE: {
    componentName: 'CustomCore',
    tabName: 'core'
  }
}

const TRANSFORMERS_TAB = {
  TRANSFORMERS: {
    componentName: 'Transformers',
    tabName: 'transformers'
  }
}

const VALUE_VALIDATION_TABS = {
  ...TRANSFORMERS_TAB,
  VALUE_VALIDATION: {
    componentName: 'ValueValidation',
    tabName: 'value_validation'
  }
}

const TRANSCRIPTION_CORRECTION_TAB = {
  TRANSCRIPTION_CORRECTION: {
    componentName: 'TranscriptionCorrection',
    tabName: 'transcription_correction'
  }
}
const CONVERSATION_FLOW_TAB = {
  CONVERSATION_FLOW: {
    componentName: 'ConversationFlow',
    tabName: 'conversation_flow'
  }
}

const VALUE_EXTRACTION_TAB = {
  VALUE_EXTRACTION: {
    componentName: 'ValueExtraction',
    tabName: 'value_extraction'
  }
}

const POST_FULFILLMENT_TAB = {
  POST_FULFILLMENT: {
    componentName: 'PostFulfillment',
    tabName: 'post_fulfillment'
  }
}

const CONVERSATION_TABS = Object.assign({}, CORE_TABS, {
  ...CONVERSATION_FLOW_TAB,
  ...TRANSFORMERS_TAB
})

const BOOLEAN_TABS = Object.assign({}, CONVERSATION_TABS, {
  ...VALUE_EXTRACTION_TAB,
  ...TRANSCRIPTION_CORRECTION_TAB,
  ...TRANSFORMERS_TAB
})

const NLP_TABS = Object.assign({}, CORE_TABS, {
  ...CONVERSATION_FLOW_TAB,
  ...VALUE_EXTRACTION_TAB,
  ...TRANSCRIPTION_CORRECTION_TAB,
  ...VALUE_VALIDATION_TABS // include transformers
})

const FREE_DATA_TABS = Object.assign({}, CORE_TABS, {
  ...CONVERSATION_FLOW_TAB,
  ...TRANSCRIPTION_CORRECTION_TAB,
  ...VALUE_VALIDATION_TABS // include transformers
})

const CONTEXT_TABS = Object.assign({}, CORE_TABS, VALUE_VALIDATION_TABS) // include transformers
const EXTERNAL_FULFILLMENT_TABS = Object.assign({}, CONTEXT_TABS, {
  FULFILLMENT: {
    componentName: 'Fulfillment',
    tabName: 'fulfillment'
  }
})

const GENERATIVE_AI_TABS = Object.assign({}, CONTEXT_TABS, {
  FULFILLMENT: {
    componentName: 'GenerativeAiFulfillment',
    tabName: 'fulfillment'
  }
})

const CONTEXT_SLOT_DATA_TYPES = {
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'boolean',
  DATE: 'date',
  RANGE: 'range'
}

const config = {
  CORE_TABS,
  EXTRACTION_SOURCE_TABS: {
    [EXTRACTION_SOURCES.CONTEXT.value]: Object.assign({}, CONTEXT_TABS, { ...POST_FULFILLMENT_TAB }),
    [EXTRACTION_SOURCES.FREE_DATA.value]: Object.assign({}, FREE_DATA_TABS, { ...POST_FULFILLMENT_TAB }),
    [EXTRACTION_SOURCES.BOOLEAN.value]: Object.assign({}, BOOLEAN_TABS, { ...POST_FULFILLMENT_TAB }),
    [EXTRACTION_SOURCES.NLP_ENTITY.value]: Object.assign({}, NLP_TABS, { ...POST_FULFILLMENT_TAB }),
    [EXTRACTION_SOURCES.EXTERNAL_FULFILLMENT.value]: Object.assign({}, EXTERNAL_FULFILLMENT_TABS, {
      ...POST_FULFILLMENT_TAB
    }),
    [EXTRACTION_SOURCES.GENERATIVE_AI.value]: Object.assign({}, GENERATIVE_AI_TABS, { ...POST_FULFILLMENT_TAB }),
    [EXTRACTION_SOURCES.DELEGATED.value]: Object.assign({}, EXTERNAL_FULFILLMENT_TABS, {
      ...POST_FULFILLMENT_TAB
    })
  },
  DISPLAYED_ACTION_LIMIT: 3,
  EXTRACTION_SOURCES,
  INTENTS: INTENTS,
  DEFAULT_INTENTS: ['small_talk_yes', 'small_talk_no'],
  FLOW_STEPS: {
    REQUEST: {
      value: 'REQUEST',
      canBeDisabled: false,
      hasContextValue: false,
      canSlotTokenBeIncludedInAnswer: false,
      isHidden: false
    },
    NOT_MATCHING: {
      value: 'NOT_MATCHING',
      canBeDisabled: false,
      hasContextValue: false,
      canSlotTokenBeIncludedInAnswer: false,
      isHidden: false
    },
    NOT_VALID: {
      value: 'NOT_VALID',
      canBeDisabled: false,
      hasContextValue: false,
      canSlotTokenBeIncludedInAnswer: false,
      isHidden: false
    },
    CONFIRM_REQUEST: {
      value: 'CONFIRM_REQUEST',
      canBeDisabled: true,
      hasContextValue: false,
      canSlotTokenBeIncludedInAnswer: true,
      isHidden: false
    },
    ACKNOWLEDGEMENT: {
      value: 'ACKNOWLEDGEMENT',
      canBeDisabled: true,
      hasContextValue: false,
      canSlotTokenBeIncludedInAnswer: true,
      isHidden: true // @TODO:Acknowledgement - Slots Acknowledgement step hidden for now
    },
    SLOT_FULFILLMENT: {
      value: 'SLOT_FULFILLMENT',
      canBeDisabled: true,
      hasContextValue: true,
      canSlotTokenBeIncludedInAnswer: true,
      isHidden: false
    }
  },
  VALUE_VALIDATION_TYPES: {
    LIST: {
      value: 'LIST',
      fieldName: 'list'
    },
    RANGE: {
      value: 'RANGE',
      fieldName: 'rangeList'
    },
    NUMBER_RANGE_LIST: {
      value: 'NUMBER_RANGE_LIST',
      fieldName: 'numberRangeList'
    },
    DATE_INTERVAL_RANGE_LIST: {
      value: 'DATE_INTERVAL_RANGE_LIST',
      fieldName: 'dateIntervalRangeList'
    },
    DATE_DURATION_RANGE_LIST: {
      value: 'DATE_DURATION_RANGE_LIST',
      fieldName: 'dateDurationRangeList'
    },
    REGEX: {
      value: 'REGEX',
      fieldName: 'regex'
    },
    API: {
      value: 'API',
      fieldName: 'endPoint'
    }
  },
  VALUE_VALIDATION: {
    GOOD: 'GOOD',
    WRONG: 'WRONG',
    INVALID: 'INVALID'
  },
  SLOT_ANSWERS_CONDITIONS: {
    BOOLEAN: ['approval', 'refusal']
  },
  MASTER_PATH: '/admin/slots',
  ASSISTANT_COMMON_PATH: '/flows/slots',
  /**
   * Slot localizedNames Index key limit is (1024 bytes). To make sure for not getting "key too large to index" error
   * while saving the slot core settings, the slot names limited to 50 characters
   */
  SLOT_NAME_MAX_LENGTH: 50,
  CONTEXT_SLOT_DATA_TYPES,
  EXTERNAL_FULFILLMENT_TYPES: {
    [CONTEXT_SLOT_DATA_TYPES.BOOLEAN]: CONTEXT_SLOT_DATA_TYPES.BOOLEAN,
    [CONTEXT_SLOT_DATA_TYPES.STRING]: CONTEXT_SLOT_DATA_TYPES.STRING,
    [CONTEXT_SLOT_DATA_TYPES.NUMBER]: CONTEXT_SLOT_DATA_TYPES.NUMBER
  },
  GENERATIVE_AI_TYPES: {
    [CONTEXT_SLOT_DATA_TYPES.BOOLEAN]: CONTEXT_SLOT_DATA_TYPES.BOOLEAN,
    [CONTEXT_SLOT_DATA_TYPES.STRING]: CONTEXT_SLOT_DATA_TYPES.STRING,
    [CONTEXT_SLOT_DATA_TYPES.NUMBER]: CONTEXT_SLOT_DATA_TYPES.NUMBER
  },
  CONTEXT_SLOT_VALUE_PLACEHOLDER: 'context_slot_value_placeholder',
  ENTITY_VALUE_PLACEHOLDER: 'entity_value_placeholder',
  EXTERNAL_FULFILLMENT_VALUE_PLACEHOLDER: 'external_fulfillment_value_placeholder',
  GENERATIVE_AI_VALUE_PLACEHOLDER: 'generative_ai_value_placeholder',
  FULFILLMENT_ENDPOINT_METHODS: {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT'
  },
  DEFAULT_TEST_STRING: 'test',
  DEFAULT_TEST_NUMBER: 1,
  TEST_STRING_LENGTH: 10,
  VALUE_VALIDATION_DEFAULT_TIMEOUT: 10000, // in ms
  CONTEXT_SLOT_DEFAULT_VALUE: {
    conversation_whole_history: 'message',
    conversation_history: 'message',
    conversations_number: 1,
    conversation_id: '611e6a95bf75a020110fc1e2',
    twilio_call_sid: '611e6a95bf75a020110fc1e2',
    conversation_start_date: '2021-08-23T17:10:08.922Z',
    whatsapp_phone_number: '+4930609859535',
    whatsapp_user_name: 'User name',
    twilio_phone_number: '+4930609859535',
    platform_type: constants.PLATFORM_TYPE.website
  },
  SESSION_SAVE: {
    LOCATIONS: {
      CONVERSATION: 'conversation',
      PROFILE: 'profile'
    },
    STATUS_OPTIONS: {},
    CONVERSATION_LOCATION_STATUSES: {},
    PROFILE_LOCATION_STATUSES: {}
  },
  // Regex for the math operation with operator and number
  // E.g. =n+2 or =n/35
  MATH_OPERATION_REGEX: new RegExp(/^=n[+\-\*\/](\d+)$/m),
  DATE_VALUE_VALIDATION_OPTIONS: {
    INTERVAL: 'interval',
    DURATION: 'duration'
  },
  ACCEPT_VALUES_OPTIONS: {
    ACCEPT_ONLY_VALID_VALUE: true,
    ACCEPT_ANY_VALUE: false
  },
  ACCEPT_MATCH_VALUE_OPTIONS: {
    ACCEPT_MATCH_ONE_VALUE: true,
    ACCEPT_MATCH_ANY_VALUE: false
  },
  DATE_UNITS: {
    DAYS: 'days',
    months: 'months',
    years: 'years'
  },
  SLOT_UPDATE_FIELDS: {
    VALUE: 'value'
  },
  SLOT_CONTEXT_REQUIREMENT_FIELDS: {
    CAN_SLOT_VALUE_BE_EMPTY: 'canSlotValueBeEmpty',
    CAN_SLOT_VALUE_BE_ANYTHING: 'canSlotValueBeAnything',
    POSSIBLE_SLOT_VALUES: 'possibleSlotValues'
  },
  SLOT_VALUE_TO_SEND_OPTIONS: {
    ORIGINAL_VALUE: 'original_value',
    SLOT_VALUE: 'slot_value',
    BOTH: 'both'
  },
  DEFAULT_KEYPAD_FILL: {
    TIMEOUT: {
      MIN: 2,
      DEFAULT: 10,
      MAX: 60
    },
    NUM_DIGITS: {
      MIN: 0,
      DEFAULT: 1,
      MAX: 50
    }
  },
  EXTRACTION_AI_FAILED_VALUE: 'no_extract',
  EXTRACTION_AI_NB_EXAMPLES: 3,
  EXTRACTION_AI_USER_SENTENCE_TOKEN: '{user_sentence}',
  // set up below
  ACTION_VALIDATION_VALUES: {},
  CAN_BE_USED_IN_ANSWER: {},
  CAN_HAVE_KEYPAD_FILL: {},
  CONTEXT_SLOT_VALUES: {},
  CONTEXT_SLOT_VALUES_LIST: {},
  DB_RANGE_PROPERTIES: {},
  EXTRACTION_SOURCES_WITHOUT_NOT_MATCHING_STEP: {},
  FLOW_STEPS_BY_VALIDATION_VALUE: {},
  OPTIONAL_FLOW_STEPS: {},
  NO_CONVERSATION_STEP_SLOTS: {},
  NOT_FOLLOWED_BY_USER_MESSAGE_STEPS: {},
  NOT_INCLUDED_STEPS: {},
  STRING_VALUE_TYPE_SLOTS: {},
  VALUE_VALIDATION_METHODS_PER_TYPE: {},
  VALUE_VALIDATION_REQUIRED_ELEMENTS: {},
  CONTEXT_PHONE_NUMBER_TYPES: {},
  EXTRACTION_SOURCE_WITH_FULFILLMENT: []
}

config.SESSION_SAVE.STATUS_OPTIONS = {
  NONE: 'none',
  USER_CONVERSATION: config.SESSION_SAVE.LOCATIONS.CONVERSATION,
  USER_CONVERSATION_AND_PROFILE: `${config.SESSION_SAVE.LOCATIONS.CONVERSATION}_${config.SESSION_SAVE.LOCATIONS.PROFILE}`
}

config.SESSION_SAVE.CONVERSATION_LOCATION_STATUSES = Object.values(config.SESSION_SAVE.STATUS_OPTIONS).filter(status =>
  status.includes(config.SESSION_SAVE.LOCATIONS.CONVERSATION)
)

config.SESSION_SAVE.PROFILE_LOCATION_STATUSES = Object.values(config.SESSION_SAVE.STATUS_OPTIONS).filter(status =>
  status.includes(config.SESSION_SAVE.LOCATIONS.PROFILE)
)

config.CONTEXT_SLOT_VALUES = {
  conversation_whole_history: {
    name: 'conversation_whole_history',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    // update : we want to store conversation_whole_history at the conversation level to use it in postCallAction
    excludedSessionSaveStatus: config.SESSION_SAVE.PROFILE_LOCATION_STATUSES
  },
  conversation_history: {
    name: 'conversation_history',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    // update : we want to store conversation_history at the conversation level to use it in postCallAction
    excludedSessionSaveStatus: config.SESSION_SAVE.PROFILE_LOCATION_STATUSES
  },
  conversations_number: {
    name: 'conversations_number',
    type: CONTEXT_SLOT_DATA_TYPES.NUMBER,
    excludedSessionSaveStatus: config.SESSION_SAVE.PROFILE_LOCATION_STATUSES
  },
  conversation_id: {
    name: 'conversation_id',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: config.SESSION_SAVE.PROFILE_LOCATION_STATUSES
  },
  conversation_start_date: {
    name: 'conversation_start_date',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: config.SESSION_SAVE.PROFILE_LOCATION_STATUSES
  },
  whatsapp_phone_number: {
    name: 'whatsapp_phone_number',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  whatsapp_user_name: {
    name: 'whatsapp_user_name',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  twilio_phone_number: {
    name: 'twilio_phone_number',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  twilio_assistant_phone_number: {
    name: 'twilio_assistant_phone_number',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  twilio_call_sid: {
    name: 'twilio_call_sid',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  twilio_sip_uui: {
    name: 'twilio_sip_uui',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  action_count: {
    name: 'action_count',
    type: CONTEXT_SLOT_DATA_TYPES.NUMBER,
    excludedSessionSaveStatus: config.SESSION_SAVE.CONVERSATION_LOCATION_STATUSES,
    masterPropName: 'actionCountMasterActionId',
    propName: 'actionCountActionId'
  },
  actions_count: {
    name: 'actions_count',
    type: CONTEXT_SLOT_DATA_TYPES.NUMBER,
    excludedSessionSaveStatus: config.SESSION_SAVE.CONVERSATION_LOCATION_STATUSES
  },
  action_history: {
    name: 'action_history',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: config.SESSION_SAVE.CONVERSATION_LOCATION_STATUSES
  },
  platform_type: {
    name: 'platform_type',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  campaign_id: {
    name: 'campaign_id',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: config.SESSION_SAVE.PROFILE_LOCATION_STATUSES
  },
  identified_caller: {
    name: 'identified_caller',
    type: CONTEXT_SLOT_DATA_TYPES.BOOLEAN,
    excludedSessionSaveStatus: []
  },
  filled_from_webchat: {
    name: 'filled_from_webchat',
    type: CONTEXT_SLOT_DATA_TYPES.STRING,
    excludedSessionSaveStatus: []
  },
  can_send_sms: {
    name: 'can_send_sms',
    type: CONTEXT_SLOT_DATA_TYPES.BOOLEAN,
    excludedSessionSaveStatus: []
  },
  current_date: {
    name: 'current_date',
    type: CONTEXT_SLOT_DATA_TYPES.DATE,
    excludedSessionSaveStatus: []
  }
}

config.CONTEXT_PHONE_NUMBER_TYPES = [
  config.CONTEXT_SLOT_VALUES.whatsapp_phone_number.name,
  config.CONTEXT_SLOT_VALUES.twilio_phone_number.name,
  config.CONTEXT_SLOT_VALUES.twilio_assistant_phone_number.name
]

const COMMON_VALUE_VALIDATION_METHODS = {
  [config.VALUE_VALIDATION_TYPES.REGEX.value]: { ...config.VALUE_VALIDATION_TYPES.REGEX },
  [config.VALUE_VALIDATION_TYPES.API.value]: { ...config.VALUE_VALIDATION_TYPES.API }
}

config.VALUE_VALIDATION_METHODS_PER_TYPE = {
  [CONTEXT_SLOT_DATA_TYPES.STRING]: {
    [config.VALUE_VALIDATION_TYPES.LIST.value]: { ...config.VALUE_VALIDATION_TYPES.LIST },
    ...COMMON_VALUE_VALIDATION_METHODS
  },
  [CONTEXT_SLOT_DATA_TYPES.RANGE]: {
    [config.VALUE_VALIDATION_TYPES.RANGE.value]: { ...config.VALUE_VALIDATION_TYPES.RANGE },
    ...COMMON_VALUE_VALIDATION_METHODS
  }
}

config.ACTION_VALIDATION_VALUES = {
  NLP_ENTITY: [config.VALUE_VALIDATION.GOOD, config.VALUE_VALIDATION.WRONG, config.VALUE_VALIDATION.INVALID],
  FREE_DATA: [config.VALUE_VALIDATION.GOOD],
  CONTEXT: [],
  BOOLEAN: [config.VALUE_VALIDATION.GOOD, config.VALUE_VALIDATION.WRONG],
  EXTERNAL_FULFILLMENT: [],
  GENERATIVE_AI: [],
  DELEGATED: []
}

config.FLOW_STEPS_BY_VALIDATION_VALUE = {
  GOOD: [
    config.FLOW_STEPS.REQUEST.value,
    config.FLOW_STEPS.CONFIRM_REQUEST.value,
    config.FLOW_STEPS.SLOT_FULFILLMENT.value
  ],
  WRONG: [config.FLOW_STEPS.REQUEST.value, config.FLOW_STEPS.NOT_MATCHING.value],
  INVALID: [config.FLOW_STEPS.REQUEST.value, config.FLOW_STEPS.NOT_VALID.value]
}

config.NOT_FOLLOWED_BY_USER_MESSAGE_STEPS = [
  config.FLOW_STEPS.SLOT_FULFILLMENT.value,
  config.FLOW_STEPS.NOT_MATCHING.value,
  config.FLOW_STEPS.NOT_VALID.value
]

config.OPTIONAL_FLOW_STEPS = [config.FLOW_STEPS.CONFIRM_REQUEST.value, config.FLOW_STEPS.SLOT_FULFILLMENT.value]

config.CAN_BE_USED_IN_ANSWER = [
  EXTRACTION_SOURCES.NLP_ENTITY.value,
  EXTRACTION_SOURCES.BOOLEAN.value,
  EXTRACTION_SOURCES.CONTEXT.value,
  EXTRACTION_SOURCES.FREE_DATA.value,
  EXTRACTION_SOURCES.EXTERNAL_FULFILLMENT.value,
  EXTRACTION_SOURCES.GENERATIVE_AI.value,
  EXTRACTION_SOURCES.DELEGATED.value
]
config.EXTRACTION_SOURCES_WITHOUT_NOT_MATCHING_STEP = [EXTRACTION_SOURCES.FREE_DATA.value]
config.NO_CONVERSATION_STEP_SLOTS = [
  EXTRACTION_SOURCES.CONTEXT.value,
  EXTRACTION_SOURCES.EXTERNAL_FULFILLMENT.value,
  EXTRACTION_SOURCES.GENERATIVE_AI.value
]
config.NOT_INCLUDED_STEPS = {
  [EXTRACTION_SOURCES.CONTEXT.value]: [],
  [EXTRACTION_SOURCES.FREE_DATA.value]: [config.FLOW_STEPS.NOT_MATCHING.value],
  [EXTRACTION_SOURCES.BOOLEAN.value]: [config.FLOW_STEPS.NOT_VALID.value],
  [EXTRACTION_SOURCES.NLP_ENTITY.value]: [],
  [EXTRACTION_SOURCES.EXTERNAL_FULFILLMENT.value]: [],
  [EXTRACTION_SOURCES.GENERATIVE_AI.value]: [],
  [EXTRACTION_SOURCES.DELEGATED.value]: [config.FLOW_STEPS.REQUEST.value, config.FLOW_STEPS.NOT_MATCHING.value]
}
config.CAN_HAVE_KEYPAD_FILL = [
  EXTRACTION_SOURCES.NLP_ENTITY.value,
  EXTRACTION_SOURCES.BOOLEAN.value,
  EXTRACTION_SOURCES.FREE_DATA.value
]
config.EXTRACTION_SOURCE_WITH_FULFILLMENT = [
  EXTRACTION_SOURCES.EXTERNAL_FULFILLMENT.value,
  EXTRACTION_SOURCES.DELEGATED.value
]

config.STRING_VALUE_TYPE_SLOTS = [EXTRACTION_SOURCES.FREE_DATA.value]

config.CONTEXT_SLOT_VALUES_LIST = Object.values(config.CONTEXT_SLOT_VALUES).map(item => item.name)

config.DB_RANGE_PROPERTIES = ['slug', '_id']
const defaultRangeElements = ['rangeTitle']
const commonElements = config.DB_RANGE_PROPERTIES.concat(defaultRangeElements)
const numberRangeElements = ['rangeStart', 'rangeEnd']
const dateRangeElements = ['dateRangeStart', 'dateRangeEnd']

config.VALUE_VALIDATION_REQUIRED_ELEMENTS = {
  [config.VALUE_VALIDATION_TYPES.NUMBER_RANGE_LIST.value]: commonElements.concat(numberRangeElements),
  [config.VALUE_VALIDATION_TYPES.DATE_INTERVAL_RANGE_LIST.value]: commonElements.concat(dateRangeElements),
  [config.VALUE_VALIDATION_TYPES.DATE_DURATION_RANGE_LIST.value]: commonElements.concat(numberRangeElements, [
    'rangeUnit'
  ]),
  [config.VALUE_VALIDATION_TYPES.LIST.value]: ['dictionary', 'value'],
  [config.VALUE_VALIDATION_TYPES.REGEX.value]: ['regex'],
  [config.VALUE_VALIDATION_TYPES.API.value]: ['endPoint']
}
export default config
