const MODELS = {
  'gpt-3.5-turbo': {
    VALUE: 'gpt-3.5-turbo',
    NAME: 'GPT-3.5 Turbo'
  },
  'gpt-3.5-turbo-16k': {
    VALUE: 'gpt-3.5-turbo-16k',
    NAME: 'GPT-3.5 Turbo 16k'
  },
  'gpt-4': {
    VALUE: 'gpt-4',
    NAME: 'GPT-4'
  },
  'gpt-4-1106-preview': {
    VALUE: 'gpt-4-1106-preview',
    NAME: 'GPT-4 1106 Preview'
  },
  'gpt-4-32k': {
    VALUE: 'gpt-4-32k',
    NAME: 'GPT-4 32k'
  },
  'gpt-4o': {
    VALUE: 'gpt-4o',
    NAME: 'GPT-4o'
  },
  'gpt-4o-mini': {
    VALUE: 'gpt-4o-mini',
    NAME: 'GPT-4o Mini'
  }
}

const ASSISTANT_PROMPT_FIELDS = {
  ROLE: {
    NAME: 'role',
    DEFAULT_VALUE: '',
    TYPE: 'string'
  },
  FORMAT: {
    NAME: 'format',
    DEFAULT_VALUE: '',
    TYPE: 'string'
  },
  RULES: {
    NAME: 'rules',
    DEFAULT_VALUE: [],
    TYPE: 'stringArray'
  },
  WARNINGS: {
    NAME: 'warnings',
    DEFAULT_VALUE: [],
    TYPE: 'stringArray'
  }
}

const DEFAULT_ASSISTANT_PROMPT_DEFINITION = Object.values(ASSISTANT_PROMPT_FIELDS).reduce((acc, field) => {
  acc[field.NAME] = field.DEFAULT_VALUE
  return acc
}, {})

export default {
  MODELS,
  DEFAULT_MODEL: MODELS['gpt-4o-mini'].VALUE,
  DEFAULT_FALLBACK_MODEL: MODELS['gpt-4'].VALUE,
  DEFAULT_NLP_EXTRACTION_MODEL: MODELS['gpt-4o-mini'].VALUE,
  ASSISTANT_PROMPT_FIELDS,
  DEFAULT_ASSISTANT_PROMPT_DEFINITION,
  AI_GENERATION_STEPS: {
    CONTENT: {
      orderPosition: 1,
      title: 'content',
      componentName: 'Content'
    },
    PROMPT: {
      orderPosition: 2,
      title: 'prompt',
      componentName: 'Prompt'
    },
    PHRASES: {
      orderPosition: 3,
      title: 'phrases',
      componentName: 'Phrases'
    }
  },
  NB_TRAINING_PHRASES: 3,
  FALLBACK_GENERATIVE_AI: {
    DEFAULT_SETTING: {
      nbPreviousConversationInteractions: 4,
      nbFallbackMessagesBeforeAI: 0,
      assistantPromptDefinition: DEFAULT_ASSISTANT_PROMPT_DEFINITION
    },
    NB_INTERACTIONS_LIMIT: {
      MIN: 0,
      MAX: 20
    },
    NB_FALLBACK_MSG_LIMIT: {
      MIN: 0,
      MAX: 20
    }
  },
  NB_INTERACTIONS_LIMIT: {
    MIN: 0,
    MAX: 50
  },
  KNOWLEDGE_SOURCE_TABS: {
    SOURCES: 'sources',
    SETTINGS: 'settings'
  },
  RAG_UPDATE_FREQUENCY: {
    NONE: 'none',
    DAILY: 'daily',
    WEEKLY: 'weekly'
  },
  RAG_DOCUMENT_MAX_FILE_SIZE: 52428800, // 50 Mo
  RAG_DOCUMENT_ACCEPTED_EXTENSIONS: ['pdf', 'doc', 'docx'],
  RAG_DOCUMENT_ACCEPTED_CONTENT_TYPES_STRING:
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  RAG_UPDATE_TIMEOUT: 180000, // in milliseconds
  RAG_CUSTOM_TOP_K: {
    MIN: 1,
    MAX: 15,
    DEFAULT: 5
  },
  RAG_CUSTOM_MIN_SIMILARITY_SCORE: {
    MIN: 0.1,
    MAX: 1,
    DEFAULT: 0.5
  },
  AI_RESPONSE_MODE_OPTIONS: {
    hybrid: 'hybrid',
    workflow_only: 'workflow_only',
    generative_ai_only: 'generative_ai_only'
  }
}
